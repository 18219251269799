<template>
  <v-dialog v-model="dialog" width="900">
    <v-card>
      <v-card-title>Palautus {{ deposit.name }} </v-card-title>

      <v-card-text>
        <div v-if="deposit.relatedContract" class="mb-2">
          <h3 class="mb-1">Sopimus</h3>
          <p class="text-label">
            <strong> Vuokralainen: </strong>
            {{
              deposit.relatedContract.tenant.name +
              deposit.relatedContract.otherTenants.reduce((sum, cur) => sum + `, ${cur.name}`, "")
            }}
          </p>
          <p class="text-label">
            <strong> Vuokrakohde: </strong>
            <span>{{ deposit.relatedContract.apartment.id.address }}</span>
            <span>{{
              deposit.relatedContract.apartment.id.apartmentNumber
                ? `, ${deposit.relatedContract.apartment.id.apartmentNumber}`
                : ""
            }}</span>
          </p>
        </div>

        <!-- Related apartment and tenants -->
        <div v-else class="mb-2">
          <h3 class="mb-1">Sopimus</h3>
          <p class="text-label">
            <strong> Vuokralainen: </strong>
            {{ getRelatedTenants() }}
          </p>
          <p class="text-label">
            <strong> Vuokrakohde: </strong>
            <span>{{ deposit.relatedApartment.address }}</span>
            <span>{{
              deposit.relatedApartment.apartmentNumber
                ? `, ${deposit.relatedApartment.apartmentNumber}`
                : ""
            }}</span>
          </p>
        </div>

        <h3 class="mb-1">Tyyppi ja talletus</h3>
        <p class="text-label">
          <strong> Vakuuden nimi: </strong>
          {{ deposit.name }}
        </p>
        <p class="text-label">
          <strong> Tallennustapa: </strong>
          {{ getDepositType() }}
        </p>

        <v-divider class="mb-2 mt-2"></v-divider>

        <h3 class="mb-1 mt-1">Vastaanotetut vakuudet</h3>

        <div v-for="(item, index) in deposit.received" :key="index + 'a'">
          <p class="text-label">
            <strong> Päiväys: </strong>
            {{ formatDate(item.date) }},
            <strong> Summa: </strong>
            <span>{{ formatCurrency(item.amount) }}</span>
          </p>
        </div>

        <v-divider class="mb-3 mt-2"></v-divider>

        <h3 class="mb-1 mt-1">Palautetut vakuudet</h3>
        <v-form ref="form">
          <div v-for="(item, index) in deposit.released" :key="index + 'b'">
            <div class="mb-1">
              <p>
                Palautus {{ index + 1 }}
                <span class="ml-4 delete-btn error--text" text @click="deleteReleased(index)"
                  >Poista</span
                >
              </p>
            </div>
            <v-row dense no-gutters>
              <v-col cols="6" sm="5" md="3" :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }">
                <v-text-field
                  v-model.number="item.amount"
                  dense
                  outlined
                  type="number"
                  step="0.01"
                  label="Määrä"
                  suffix="€"
                  :rules="largerThan0"
                ></v-text-field>
              </v-col>

              <v-col cols="6" sm="6" md="4" :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }">
                <v-menu
                  :ref="item.releaseDateMenu"
                  v-model="item.releaseDateMenu"
                  :close-on-content-click="false"
                  min-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.dateFormattedReleaseDate"
                      dense
                      outlined
                      v-bind="attrs"
                      :rules="required"
                      v-on="on"
                      append-icon="mdi-calendar"
                      label="Maksupäivä"
                      v-prevent-manual-input
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.date"
                    first-day-of-week="1"
                    @input="item.releaseDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="5" md="4" :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }">
                <v-select
                  v-model.number="item.useType"
                  :items="useTypes"
                  item-text="text"
                  item-value="val"
                  dense
                  outlined
                  label="Käyttökohde"
                  :rules="positiveNumber"
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <div class="details-container">
                  <v-textarea
                    v-model="item.comment"
                    dense
                    outlined
                    label="Palautuksen kommentti"
                    placeholder="Esim. kenelle palautettu tai miten palautus käytettiin..."
                    rows="3"
                    :rules="required"
                    v-show="item.edit"
                    @blur="item.edit = item.comment == '' ? true : false"
                  ></v-textarea>

                  <!-- release comment -->
                  <div v-show="!item.edit" class="mb-1">
                    <p style="white-space: pre-line">
                      {{ item.comment }}
                      <v-icon @click="item.edit = true" small>mdi-pencil</v-icon>
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
          </div>
        </v-form>

        <v-row dense>
          <v-col>
            <v-btn small color="primary" class="mr-4 mb-3" @click="addReleased"
              >Lisää uusi palautus</v-btn
            >
          </v-col>
        </v-row>

        <!-- Comments -->
        <v-row dense v-if="deposit.comments.length > 0">
          <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Kommenttihistoria ({{ deposit.comments.length }})</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <div v-for="(comment, index) in deposit.comments" :key="index + 'c'">
                    <p style="margin-top: 6px; margin-bottom: -2px">{{ comment.text }}</p>
                    <small>{{ comment.createdBy.name }}, {{ formatDate(comment.createdAt) }}</small>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @mousedown.prevent="depositRelease"> Tallenna</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import { eventBus } from "../../main";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data(vm) {
    return {
      deposit: {
        comments: [],
        released: [],
        relatedTenants: [],
        relatedApartment: {},
      },
      depositTypes: [
        { text: "Pankkitakaus", val: "bank" },
        { text: "Maksettu vuokranantajan tilille", val: "payment" },
        { text: "Kelan / Sosiaalitoimen maksusitoumus", val: "commitment" },
        { text: "Henkilötakaus", val: "guarantee" },
        { text: "Asiakasvaratili", val: "customerReserveAccount" },
      ],
      useTypes: [
        { text: "Vuokralainen", val: 0 },
        { text: "Vuokranantaja", val: 1 },
        { text: "Muu kulu", val: 2 },
      ],
      names: ["Vuokravakuus", "Avainpantti"],
      required: [(v) => !!v || "Pakollinen kenttä"],
      positiveNumber: [(v) => vm.testNumber(v) || "Summan pitää olla 0 tai suurempi"],
      largerThan0: [(v) => vm.testNumber2(v) || "Summan pitää olla suurempi kuin 0"],
      maxAmount: [(v) => vm.testMaxAmount(v) || `Maksimisumma on ${vm.deposit.amount} €`],
    };
  },

  computed: {
    ...mapState("deposit", ["currentDeposit"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    "deposit.received": {
      deep: true,
      handler(arr) {
        if (this.value) {
          arr.forEach((el) => {
            el.dateFormattedReceiveDate = this.formatDate(el.date);
          });
        }
      },
    },

    "deposit.released": {
      deep: true,
      handler(arr) {
        if (this.value) {
          arr.forEach((el) => {
            el.dateFormattedReleaseDate = this.formatDate(el.date);
          });
        }
      },
    },

    currentDeposit(deposit) {
      if (deposit && this.value) {
        // Format dates
        deposit.received.forEach((el) => {
          el.date = new Date(el.date).toISOString().substring(0, 10);
        });

        deposit.released.forEach((el) => {
          el.date = new Date(el.date).toISOString().substring(0, 10);
          el.edit = false;
        });

        this.deposit = _.cloneDeep(deposit);
      }
    },
  },

  methods: {
    ...mapActions("deposit", ["createDeposit", "editDeposit", "releaseDeposit"]),

    addReleased() {
      this.deposit.released.push({
        date: "",
        amount: null,
        releaseDateMenu: false,
        dateFormattedReleaseDate: "",
        comment: "",
        useType: 0,
        edit: true,
      });
    },

    async depositRelease() {
      if (this.$refs.form.validate()) {
        if (!this.testReleasedTotal()) {
          return this.showPopup(
            "Palautettujen vakuuksien summa ei voi ylittää vastaanotettujen vakuuksien summaa.",
            "error"
          );
        }

        await this.releaseDeposit(this.deposit);
        eventBus.$emit("setShowResults", false);
      } else {
        this.showPopup(
          "Katso että kaikki kentät on täydennetty. Myös palautuksen kommentti on pakollinen kenttä.",
          "error"
        );
      }
    },

    getDepositType() {
      const val = this.depositTypes.filter((el) => el.val == this.deposit.depositType);
      if (val.length > 0) return val[0].text;
    },

    testMaxAmount(val) {
      return val > this.deposit.amount ? false : true;
    },

    testNumber(val) {
      if (val == undefined || val < 0) {
        return false;
      }
      return val >= 0 ? true : false;
    },

    testNumber2(val) {
      if (val > 0) return true;
      else return false;
    },

    testReleasedTotal() {
      return (
        this.deposit.released.reduce((sum, item) => sum + item.amount, 0) <=
        this.deposit.received.reduce((sum, item) => sum + item.amount, 0)
      );
    },

    getRelatedTenants() {
      return this.deposit.relatedTenants.reduce(
        (sum, cur, i, arr) => sum + `${cur.name}${i < [arr.length - 1] ? ", " : ""}`,
        ""
      );
    },

    deleteReleased(index) {
      if (confirm("Oletko varma, että haluat poistaa palautetun vakuuden?")) {
        this.deposit.released.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-input--is-disabled {
  color: rgb(199, 199, 199);
}

.delete-btn {
  cursor: pointer;
}

.details-container {
  display: flex;
}

.text-label {
  font-weight: 500;
}
</style>
