h3
<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Vakuudet</div>

    <v-card class="mt-2 pb-2">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="isAuthorized('deposit', 'create')"
          color="info"
          class="mr-2 mb-2"
          @click.stop="
            editValue = false;
            depositDialog = true;
          "
        >
          Uusi vakuus
        </v-btn>
      </v-card-title>

      <v-container>
        <v-form ref="form">
          <span class="font-weight-bold primary--text">Hae vakuuksia</span>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="searchTenant"
                label="Vuokralainen"
                hide-details
                outlined
                dense
                @keyup.enter="searchDeposits()"
                @click:append="searchDeposits()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="searchApartment"
                label="Vuokrakohteen osoite"
                hide-details
                outlined
                dense
                @keyup.enter="searchDeposits()"
                @click:append="searchDeposits()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                v-model="depositName"
                dense
                outlined
                :items="names"
                hide-details
                label="Vakuuden tyyppi"
                :rules="required"
                @change="showResults = false"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                v-model="depositType"
                dense
                outlined
                hide-details
                :items="depositTypes"
                item-text="text"
                item-value="val"
                label="Talletustapa"
                @change="showResults = false"
              ></v-select>
            </v-col>
            <v-col v-if="query.searchBy != 4" cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                v-model="depositState"
                dense
                outlined
                :items="depositStates"
                item-text="text"
                item-value="val"
                label="Tila"
                @change="showResults = false"
                hide-details
              ></v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <!--  Start -->
            <v-col v-if="query.searchBy < 3" cols="12" sm="6" md="4" lg="3" xl="2">
              <v-menu
                ref="menuStart"
                v-model="menuStart"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormattedStart"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    label="Alkaen"
                    append-icon="mdi-calendar"
                    hide-details
                    v-prevent-manual-input
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="query.startDate"
                  first-day-of-week="1"
                  @input="menuStart = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <!-- End -->
            <v-col v-if="query.searchBy < 3" cols="12" sm="6" md="4" lg="3" xl="2">
              <div>
                <v-menu
                  ref="menuEnd"
                  v-model="menuEnd"
                  :close-on-content-click="false"
                  min-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormattedEnd"
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      label="Päättyen"
                      append-icon="mdi-calendar"
                      hide-details
                      v-prevent-manual-input
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="query.endDate"
                    first-day-of-week="1"
                    @input="menuEnd = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>

            <!-- One day -->
            <v-col v-if="query.searchBy === 3" cols="12" sm="6" md="4" lg="3" xl="2">
              <div>
                <v-menu
                  ref="menuOneDay"
                  v-model="menuOneDay"
                  :close-on-content-click="false"
                  min-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormattedOneDay"
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      label="Päivämäärä"
                      append-icon="mdi-calendar"
                      hide-details
                      :rules="required"
                      v-prevent-manual-input
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="query.oneDayDate"
                    first-day-of-week="1"
                    @input="menuOneDay = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-radio-group
                v-model="query.searchBy"
                :mandatory="true"
                style="margin-top: 0px; margin-bottom: 0px"
                @change="
                  query.oneDayDate = '';
                  showResults = false;
                "
                hide-details
              >
                <v-radio label="Maksupäivän mukaan" :value="1"></v-radio>
                <v-radio label="Palautuspäivä mukaan" :value="2"></v-radio>
                <v-radio label="Tilin saldo tiettynä päivänä" :value="3"></v-radio>
                <v-radio label="Päällekäiset vakuudet (vuokrakohteen mukaan)" :value="4"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <!-- Buttons -->
          <v-row dense>
            <v-col cols="6" md="4">
              <v-btn class="mr-5 mb-2" color="info" @click="searchDeposits()">Etsi</v-btn>
              <v-btn class="mb-2" color="error" outlined @click="resetForm()">Tyhjennä</v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-divider class="mb-2"></v-divider>

        <!--  Results -->
        <v-row dense>
          <v-col cols="12" sm="6">
            <!-- Total results -->
            <v-card class="resultsBox" max-width="400">
              <!-- Results -->
              <div v-if="showResults">
                <v-card-text style="padding-bottom: 0; margin-bottom: 20px">
                  <h3 class="mb-1">Vakuustiedot</h3>

                  <!-- By received -->
                  <div v-if="query.searchBy === 1">
                    <p>Vastaanotettuja vakuuksia yhteensä: {{ total.paymentCount }} kpl</p>
                    <p>
                      Vastaanotetut yhteensä:
                      {{ formatCurrency(total.totalAmount) }}
                    </p>
                  </div>

                  <!-- By released -->
                  <div v-if="query.searchBy === 2">
                    <p>Palautettuja vakuuksia yhteensä: {{ total.paymentCount }} kpl</p>
                    <p>
                      Palautetut yhteensä:
                      {{ formatCurrency(total.totalAmount) }}
                    </p>
                  </div>

                  <!-- One day -->
                  <div v-if="query.searchBy === 3">
                    <v-divider class="mb-1 mt-1"></v-divider>

                    <p>Vakuuden tyyppi: {{ depositName }}</p>
                    <p>Talletustapa: {{ computedDepositType }}</p>
                    <p>Saldo: {{ formatCurrency(total.receivedAmount - total.releasedAmount) }}</p>
                  </div>

                  <!-- Duplicates -->
                  <div v-if="query.searchBy === 4">
                    <v-divider class="mb-1 mt-1"></v-divider>

                    <p>Vakuuden tyyppi: {{ depositName }}</p>
                    <p>Talletustapa: {{ computedDepositType }}</p>
                    <p>Päällekkäisiä vakuuksia: {{ total.duplicates }} kpl</p>
                  </div>
                </v-card-text>

                <!-- Searching results -->
                <v-card-text v-if="loading && deposits.length == 0">
                  <h3>Etsitään tuloksia...</h3>
                </v-card-text>
              </div>
              <!-- No results -->
              <div v-else>
                <p class="ma-2">Tee haku nähdäksesi muuttuneet tulokset...</p>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" v-if="!hideTable">
            <!-- Colorcodes -->
            <div style="font-size: 90%">
              <p>
                <v-icon class="mr-1" :color="'green'" small>mdi-checkbox-blank-circle</v-icon>Vakuus
                vastaanotettu (tavoite täytetty tai tyhjä)
              </p>
              <p>
                <v-icon class="mr-1" :color="'yellow'" small>mdi-checkbox-blank-circle</v-icon
                >Vakuus vastaanotettu (tavoite ei täytetty)
              </p>
              <p>
                <v-icon class="mr-1" :color="'orange'" small>mdi-checkbox-blank-circle</v-icon
                >Osittain palautettu
              </p>
              <p>
                <v-icon class="mr-1" :color="'blue'" small>mdi-checkbox-blank-circle</v-icon
                >Kokonaan palautettu
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <div v-if="!hideTable">
        <v-data-table
          :headers="headers"
          :items="deposits"
          :footer-props="globalValues.footerProps"
          :items-per-page="getDefaultTableRows()"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalLength"
        >
          <!-- Name -->
          <template #[`item.name`]="{ item }">
            <span class="font-weight-bold"> {{ item.name }} </span>
          </template>

          <!-- DepositType -->
          <template #[`item.depositType`]="{ item }">
            <span> {{ formatDeposit(item.depositType) }} </span>
          </template>

          <!-- Tenants -->
          <template #[`item.relatedTenants`]="{ item }">
            <!-- Related tenants -->
            <div v-for="(tenant, index) in item.relatedTenants" :key="index + 'a'">
              <router-link style="text-decoration: none" :to="`/tenant/overview/${tenant._id}`">
                <span> {{ tenant.name }} </span>
              </router-link>
            </div>

            <!-- If relatedcontract -->
            <div v-if="item.relatedContract">
              <router-link
                style="text-decoration: none"
                :to="`/tenant/overview/${item.relatedContract.tenant.tenantId}`"
              >
                <span>{{ item.relatedContract.tenant.name }}</span>
              </router-link>

              <div v-for="(tenant, index) in item.relatedContract.otherTenants" :key="index + 'b'">
                <router-link
                  style="text-decoration: none"
                  :to="`/tenant/overview/${tenant.tenantId}`"
                >
                  <span>
                    {{ tenant.name }}
                  </span>
                </router-link>
              </div>
            </div>
          </template>

          <!-- Contract -->
          <template #[`item.relatedContract`]="{ item }">
            <span v-if="item.relatedContract">
              {{ item.relatedContract.contractNumber }}
            </span>
          </template>

          <!-- Apartment address -->
          <template #[`item.apartmentAddress`]="{ item }">
            <div v-if="item.relatedApartment">
              <router-link
                style="text-decoration: none"
                :to="`/apartment/overview/${item.relatedApartment._id}`"
              >
                <span :class="{ 'error--text': !item.relatedApartment.active }"
                  >{{ item.apartmentAddress }}
                </span>
              </router-link>
            </div>

            <!-- If relatedcontract -->
            <div v-if="item.relatedContract">
              <router-link
                style="text-decoration: none"
                :to="`/apartment/overview/${item.relatedContract.apartment.id._id}`"
              >
                <span :class="{ 'error--text': !item.relatedContract.apartment.id.active }"
                  >{{ item.apartmentAddress }}
                </span>
              </router-link>
            </div>
          </template>

          <!-- Owners-->
          <template #[`item.owners`]="{ item }">
            <!-- Related apartment -->
            <div v-if="item.relatedApartment">
              <div v-if="item.relatedApartment.owners.length == 0">
                {{ currentUser.currentAccount.name }}
              </div>

              <div v-else v-for="(owner, idx) in item.relatedApartment.owners" :key="'c' + idx">
                <router-link
                  v-if="owner.onModel == 'Partner'"
                  style="text-decoration: none"
                  :to="`/partner/overview/${owner.ownerId._id}`"
                >
                  <span> {{ owner.ownerId.name }}</span>
                </router-link>

                <span v-else>{{ currentUser.currentAccount.name }}</span>
              </div>
            </div>

            <!-- If relatedcontract -->
            <div v-if="item.relatedContract">
              <div v-if="item.relatedContract.apartment.id.owners.length == 0">
                {{ currentUser.currentAccount.name }}
              </div>

              <div
                v-else
                v-for="(owner, idx) in item.relatedContract.apartment.id.owners"
                :key="'d' + idx"
              >
                <router-link
                  v-if="owner.onModel == 'Partner'"
                  style="text-decoration: none"
                  :to="`/partner/overview/${owner.ownerId._id}`"
                >
                  <span>{{ owner.ownerId.name }} </span>
                </router-link>

                <span v-else>{{ currentUser.currentAccount.name }}</span>
              </div>
            </div>
          </template>

          <!-- Invoice -->
          <template #[`item.relatedInvoice`]="{ item }">
            <span v-if="item.relatedInvoice">
              {{ item.relatedInvoice.invoiceNumber }}
            </span>
          </template>

          <!-- TargetAmount -->
          <template #[`item.targetAmount`]="{ item }">
            <span> {{ formatCurrency(item.targetAmount) }} </span>
          </template>

          <!-- State -->
          <template #[`item.state`]="{ item }">
            <span>
              <v-icon small :color="getStateColor(item)">mdi-checkbox-blank-circle</v-icon>
            </span>
          </template>

          <!-- Received -->
          <template #[`item.received`]="{ item }">
            <p v-for="(i, index) in item.received" :key="index + 'a'">
              {{ formatDate(i.date) }} ({{ formatCurrency(i.amount) }})
            </p>
          </template>

          <!-- Released -->
          <template #[`item.released`]="{ item }">
            <div>
              <small style="font-size: 12px"
                >Palautusta jäljellä: {{ formatCurrency(getReleasedLeftAmount(item)) }}</small
              >
            </div>
            <p v-for="(i, index) in item.released" :key="index + 'b'">
              <span :class="i.useType == 0 ? 'success--text' : 'error--text'">
                {{ formatDate(i.date) }} ({{ formatCurrency(i.amount) }})</span
              >
            </p>

            <span v-if="item.released.length == 0">Ei palautettuja</span>
          </template>

          <!-- Related Invoice text -->
          <!-- <template #[`item.relatedInvoiceText`]="{ item }">
            <div v-if="item.relatedInvoiceText">
              <span
                v-if="
                  item.relatedInvoiceText.startsWith('http') ||
                  item.relatedInvoiceText.startsWith('www')
                "
                ><p
                  style="color: #0096c7; cursor: pointer"
                  @click="goToUrl(item.relatedInvoiceText)"
                >
                  {{ truncateString(item.relatedInvoiceText, 15) }}
                </p></span
              >

              <span v-else>{{ item.relatedInvoiceText }}</span>
            </div>
          </template> -->

          <!-- Actions -->
          <template #[`item.actions`]="{ item }">
            <div class="action-grid-container">
              <v-icon
                v-if="isAuthorized('deposit', 'edit')"
                title="Muokkaa"
                small
                @click="
                  $store.state.deposit.currentDeposit = { ...item };
                  depositDialog = true;
                  editValue = true;
                "
                >mdi-pencil</v-icon
              >
              <v-icon
                v-if="isAuthorized('deposit', 'edit')"
                title="Palauta vakuus"
                small
                @click="
                  $store.state.deposit.currentDeposit = { ...item };
                  depositReleaseDialog = true;
                "
                >mdi-lock-open</v-icon
              >
              <v-icon
                v-if="isAuthorized('contract', 'create')"
                :class="{ 'info--text': hasItems(item.files) }"
                v-model="showFileDialog"
                title="Liitä dokumentteja"
                small
                @click="
                  depositId = item._id;
                  showFileDialog = true;
                "
                >mdi-paperclip</v-icon
              >
              <v-icon
                v-if="isAuthorized('deposit', 'delete')"
                title="Poista"
                small
                @click="deleteItem(item)"
                >mdi-delete</v-icon
              >
            </div>
          </template>
          <template #no-data>
            <h2>Ei vakuuksia</h2>
          </template>
        </v-data-table>
      </div>

      <div v-else>
        <h3 style="margin-bottom: 50px" class="text-center">
          Listausta ei näytetä maksupäivän mukaan haettaessa...
        </h3>
      </div>
    </v-card>

    <!--  New / Edit Deposit -->
    <deposit-dialog v-model="depositDialog" :edit="editValue"></deposit-dialog>

    <!--  Release Deposit dialog -->
    <deposit-release-dialog v-model="depositReleaseDialog"></deposit-release-dialog>

    <!-- File dialog -->
    <File
      v-model="showFileDialog"
      :document-id="depositId"
      title="Lataa vakuuden liite"
      document="deposit"
      bucket="vuokranet-vakuudet"
      model="deposits"
      @sendnewfiles="replaceFilesArray"
    ></File>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import DepositDialog from "../../components/Deposit/DepositDialog.vue";
import DepositReleaseDialog from "../../components/Deposit/DepositReleaseDialog.vue";
import File from "../../components/File.vue";
import { eventBus } from "../../main";
import globalValues from "@/configs/globalValues";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  title: "Vakuudet",

  components: { DepositDialog, DepositReleaseDialog, File },
  mixins: [mixins],

  data: () => ({
    showFileDialog: false,
    depositId: "",
    depositDialog: false,
    depositReleaseDialog: false,
    loading: true,
    options: {},
    dialog: false,
    searchTenant: "",
    searchApartment: "",
    editValue: false,
    hideTable: false,
    showResults: false,
    depositState: "all",
    depositType: "all",
    depositName: "Kaikki",
    depositTypes: [
      { text: "Kaikki", val: "all" },
      { text: "Pankkitakaus", val: "bank" },
      { text: "Maksettu vuokranantajan tilille", val: "payment" },
      { text: "Kelan / Sosiaalitoimen maksusitoumus", val: "commitment" },
      { text: "Henkilötakaus", val: "guarantee" },
      { text: "Asiakasvaratili", val: "customerReserveAccount" },
    ],
    depositStates: [
      { text: "Kaikki", val: "all" },
      { text: "Vakuus vastaanotettu (tavoite täytetty tai tyhjä)", val: 0 },
      { text: "Vakuus vastaanotettu (tavoite ei täytetty)", val: 1 },
      { text: "Osittain palautettu", val: 2 },
      { text: "Kokonaan palautettu", val: 3 },
    ],
    names: ["Kaikki", "Vuokravakuus", "Avainpantti"],
    headers: [
      {
        text: "Vakuus",
        value: "name",
      },
      {
        text: "Talletus",
        value: "depositType",
      },
      {
        text: "Vuokralainen",
        value: "relatedTenants",
        sortable: false,
      },
      {
        text: "Sopimus",
        value: "relatedContract",
        sortable: false,
      },
      {
        text: "Vuokrakohde",
        value: "apartmentAddress",
        sortable: false,
      },
      {
        text: "Omistaja",
        value: "owners",
        sortable: false,
      },
      {
        text: "Lasku nro.",
        value: "relatedInvoice",
        sortable: false,
      },
      {
        text: "Viitenumero",
        value: "referenceNumber",
        sortable: false,
      },

      { text: "Tavoite", value: "targetAmount" },
      { text: "Tila", value: "state" },
      { text: "Maksettu", value: "received", sortable: false },
      { text: "Palautettu", value: "released", sortable: false },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    menuStart: false,
    menuEnd: false,
    menuOneDay: false,
    dateFormattedStart: "",
    dateFormattedEnd: "",
    dateFormattedOneDay: "",
    query: {
      searchBy: 1,
      startDate: null,
      endDate: null,
      oneDayDate: null,
    },
    required: [(v) => !!v || "Pakollinen kenttä"],
    globalValues,
  }),

  created() {
    eventBus.$on("setShowResults", (val) => {
      this.showResults = val;
    });
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("deposit", ["deposits", "totalLength", "total"]),
    ...mapState("browsehistory", ["dataTablePage", "getPreviousPage", "itemsPerPage"]),

    computedDepositType() {
      let text;
      this.depositTypes.forEach((el) => {
        if (el.val == this.depositType) text = el.text;
      });

      return text;
    },
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.searchDeposits();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },

    "query.endDate"() {
      this.dateFormattedEnd = this.formatDate(this.query.endDate);
    },

    "query.startDate"() {
      this.dateFormattedStart = this.formatDate(this.query.startDate);
    },

    "query.oneDayDate"() {
      this.dateFormattedOneDay = this.formatDate(this.query.oneDayDate);
    },

    "query.searchBy"(val) {
      if (val == 4) {
        this.query.startDate = "";
        this.query.endDate = "";
        this.query.oneDayDate = "";
      }
    },
  },

  methods: {
    ...mapActions("deposit", ["getDeposits", "deleteDeposit"]),
    ...mapMutations("browsehistory", ["saveTableOptions"]),

    resetForm() {
      this.query.searchBy = 1;
      this.query.endDate = null;
      this.query.startDate = null;
      this.query.oneDayDate = null;
      this.depositType = "all";
      this.depositState = "all";
      this.depositName = "Kaikki";
      this.searchTenant = "";
      this.searchApartment = "";
      this.options.page = 1;
      this.searchDeposits();
    },

    getStateColor(item) {
      const state = item.state;

      if (state == 0) return "green";
      if (state == 1) return "yellow";
      if (state == 2) return "orange";
      if (state == 3) return "blue";
    },

    goToUrl(url) {
      if (url.indexOf("//") < 0) {
        url = "http://" + url;
      }
      Object.assign(document.createElement("a"), {
        target: "_blank",
        href: url,
      }).click();
    },

    replaceFilesArray(item) {
      const index = this.deposits.findIndex((obj) => obj._id === item._id);
      if (index !== -1) {
        this.deposits[index].files = item.files;
      }
    },

    getReleasedLeftAmount(item) {
      const received = item.received.reduce((sum, received) => sum + received.amount, 0);
      const released = item.released.reduce((sum, released) => sum + released.amount, 0);

      return received - released;
    },

    async searchDeposits() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const { page, itemsPerPage, sortBy, sortDesc } = this.options;

        const searchTenant = this.searchTenant.trim().toLowerCase();
        const searchApartment = this.searchApartment.trim().toLowerCase();

        const url = `/api/v1/deposits/get-deposits?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&depositName=${this.depositName}&depositType=${this.depositType}&depositState=${this.depositState}&searchTenant=${searchTenant}&searchApartment=${searchApartment}`;

        await this.getDeposits({ url, query: this.query });

        // Hide table if search by date
        this.hideTable =
          this.query.endDate || this.query.startDate || this.query.oneDayDate ? true : false;

        // Show results box
        this.showResults = true;

        this.loading = false;
      }
    },

    deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa tämän vakuuden: " + item.name)) {
        this.deleteDeposit(item._id);
        this.showResults = false;
      }
    },
  },
};
</script>

<style scoped>
br {
  content: "A";
  display: block;
  margin-bottom: 0px;
}

.resultsBox {
  border: 1px solid var(--v-success-base);
  max-width: 400px;
}
</style>
